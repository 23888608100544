html,
body {
	font-family: $sansSerif;
}

.lowercase {
	text-transform: lowercase;
}

p {
	// font-size: 14px;
	// line-height: 1.29em;
	// letter-spacing: 0.5px;

	font-family: $serif;
	font-weight: normal;
	font-style: normal;
	letter-spacing: 0;
	color: $black;
	margin-bottom: 30px;
	font-size: 14px;
	line-height: 1.71em;

	@include breakpoint(large) {
		font-size: 16px;
		line-height: 1.63em;
	}
}


h6 {
	font-family: $serif;
	font-size: 14px;
	font-weight: normal;
	font-style: italic;
	line-height: 1.57em;
	letter-spacing: 0;
	color: $black;

	@include breakpoint(large) {
		line-height: 1.43em;
	}
}

blockquote {
	$smallSize: 24px;
	$largeSize: 32px;
	font-family: $serif;
	font-weight: normal;
	letter-spacing: 0;
	padding: 20px;
	color: $black;
	font-style: italic;
	text-shadow: 0 0 5px rgba($white, 0.5);
	position: relative;
	z-index: 0;

	font-size: $smallSize;
	line-height: 1.67em;

	@include breakpoint(large) {
		font-size: $largeSize;
		line-height: 1.38em;

	}

	&::before {
		content: "\201C";
		display: inline-block;
		width: 0;
		color: $amaranth;
		font-weight: bold;
		line-height: 0;
		position: relative;
		z-index: -1;
		opacity: 0.2;
		text-shadow: none;

		font-size: #{$smallSize * 4.6};
		top: #{$smallSize * 0.8};
		left: #{$smallSize * -1};

		@include breakpoint(large) {
			font-size: #{$largeSize * 3};
			top: #{$largeSize * 0.8};
			left: #{$largeSize * -1};
		}
	}

	&::after {
		content: "\201D";
		color: $amaranth;
		font-weight: bold;
		line-height: 0;
		position: relative;
		z-index: -1;
		opacity: 0.2;
		display: inline-block;
		width: 0;
		text-shadow: none;

		font-size: #{$smallSize * 4.6};
		margin-top: #{$smallSize * -1};
		left: #{$smallSize * -1};
		top: #{$smallSize * 1.8};

		@include breakpoint(large) {
			font-size: #{$largeSize * 3};
			margin-top: #{$largeSize * -1};
			left: #{$largeSize * -1};
			top: #{$largeSize * 1.8};
		}
	}

	+cite {
		text-align: right;
		color: $mediumGrey;
		display: block;
		margin-top: -20px;
		margin-bottom: 30px;
		font-size: #{$smallSize / 2};

		@include breakpoint(large) {
			font-size: #{$largeSize / 2};
		}
	}
}
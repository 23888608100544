$black: #000;

$white: #fff;

$red: #e83b52;
$amaranth: $red;

$lightestGrey:#faf9f9;
$lightestGray:$lightestGrey;
$pearl:$lightestGrey;

$lighterGrey: #f2f2f2;
$lighterGray: $lighterGrey;
$concrete: $lighterGrey;

$lightGrey: #cdcdcd;
$lightGray: $lightGrey;
$silver: $lightGrey;

$mediumLightGrey: #a2a2a2;
$mediumLightGray: $mediumLightGrey;
$chalice: $mediumLightGrey;

$mediumGrey: #575757;
$mediumGray: $mediumGrey;
$scorpion: $mediumGrey;

$mineShaft:#333;

$darkGrey: #1e1e1e;
$darkGray: $darkGrey;
$cod: $darkGrey;

$gold: #c29c5e;
$twine: $gold;

$header-grey: #333839;
$header-search-box: #fff;
$header-search-box-ph: #787d7e;
$eyebrow-link: #fff;
$header-global-n1: #fff;
$header-active-ul: #fff;
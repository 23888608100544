.blog-post-content {
	@include pageSection();

	&--byline {
		font-family: $sansSerif;
		font-weight: bold;
		font-style: normal;
		letter-spacing: 0;
		color: $black;
		font-size: 20px;
		line-height: 1.3em;
		margin: 0 0 20px 0;

		@include breakpoint(large) {
			font-size: 30px;
			line-height: 1.2em;
			margin: 0 0 30px 0;
		}
	}

	&--intro {
		font-family: $serif;
		font-size: 14px;
		font-weight: normal;
		font-style: italic;
		line-height: 1.43em;
		letter-spacing: 0;
		color: $black;
		margin: 0 0 20px 0;
	}

	&--tags {
		font-family: $serif;
		font-size: 14px;
		font-weight: bold;
		font-style: normal;
		line-height: 1.86em;
		letter-spacing: 0;
		color: $black;

		a {
			display: inline-block;
			text-decoration: underline;
			font-family: $sansSerif;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			line-height: 1em;
			letter-spacing: 0;
			color: $black;
			@include subtle(color);

			&:focus,
			&:hover {
				color: $amaranth;
			}
		}
	}
}
.event-detail {
	padding-bottom: 30px;

	@include breakpoint(large) {
		padding-bottom: 50px;
	}

	@include breakpoint(large) {
		display: block;
		overflow: hidden;

		>.column {
			float: left;
			display: inline;
		}
	}

	&--header {
		//.event-detail--header
		@include pageSection();
		padding-bottom: 0;

		@include breakpoint(large) {
			padding-bottom: 30px;
		}
	}

	&--date {
		//.event-detail--date
		font-family: $sansSerif;
		font-size: 22px;
		font-weight: 900;
		font-style: normal;
		line-height: 1.18em;
		letter-spacing: 0;
		color: $amaranth;
		text-transform: uppercase;
		margin: 0 0 10px 0;

		@include breakpoint(large) {
			line-height: 1em;
		}
	}

	&--location {
		//.event-detail--location
		font-family: $sansSerif;
		font-size: 18px;
		font-weight: bold;
		font-style: normal;
		line-height: 1em;
		letter-spacing: 0;
		margin: 0 0 10px 0;
		color: $black;
	}

	&--runtime {
		//.event-detail--runtime
		font-family: $serif;
		font-size: 14px;
		font-weight: bold;
		line-height: 1.57em;
		letter-spacing: 0;
		color: $black;
		margin: 0;

		em {
			font-weight: normal;
		}

		@include breakpoint(large) {
			line-height: 1.86em;

			em {
				font-weight: normal;
			}
		}
	}

	&--meta {
		//.event-detail--meta
		font-family: $sansSerif;
		font-size: 14px;
		font-weight: 300;
		font-style: normal;
		line-height: 1.71em;
		letter-spacing: 0;
		color: $black;

		strong {
			font-weight: bold;
		}

		@include breakpoint(large) {
			line-height: 1.57em;
		}
	}

	.upcoming-performances {
		margin-bottom: 30px;
		padding-bottom: 0;

		@include breakpoint(large) {
			margin-bottom: 0;
			padding-bottom: 0;
			order: -1;
			float: right;
			display: inline;
			clear: none;
		}
	}
}
.masthead {
	&--title {
		//.masthead--title
		font-family: $sansSerif;
		font-weight: bold;
		text-align: center;
		color: $black;
		text-transform: uppercase;
		font-size: 16px;
		letter-spacing: 1.5px;
		line-height: 1.5em;

		@include breakpoint(large) {
			font-size: 22px;
			letter-spacing: 2px;
			line-height: 1;
		}
	}

	&--separator {
		//.masthead--separator
		width: 30px;
		border-bottom-width: 2px;
		color: $amaranth;
		background-color: $amaranth;
		border-color: $amaranth;
		margin: #{$vSpaceSmall - 10} auto $vSpaceSmall;

		@include breakpoint(large) {
			margin: #{$vSpaceLarge - 10} auto $vSpaceLarge;
		}
	}
}
.header {
	height: 100px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: center;
	align-items: center;
	padding: 15px 15px 20px 15px;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 2;

	@include breakpoint(large) {
		padding: 20px 40px;
		flex-wrap: nowrap;
	}

	&--logo {
		//.header--logo
		flex: 0 0 auto;

		svg {
			width: 124px;
			height: 36px;

			@include breakpoint(large) {
				width: 206px;
				height: 60px;
			}
		}
	}

	&--nav {

		//.header--nav
		&--main {
			//.header--nav--main
			color: $white;
			font-family: $sansSerif;
			font-style: normal;
			font-size: 11px;
			line-height: 1;
			letter-spacing: 0.4px;
			font-weight: bold;
			text-transform: uppercase;

			margin-left: auto;

			flex: 0 0 100%;
			justify-self: flex-end;

			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;

			order: 1;

			@include breakpoint(medium) {
				// flex: 0 0 100%;
				justify-content: flex-end;
			}

			@include breakpoint(large) {
				flex: 0 0 auto;
				font-size: 14px;
				letter-spacing: 0.5px;
				order: 0;
				margin-right: 60px;
			}

			&--item {
				//.header--nav--main--item
				font-size: inherit;
				color: inherit;
				flex: 0 0 auto;
				padding: 5px 0;
				margin-right: 20px;
				text-decoration: none;
				@include subtle(color);

				@include breakpoint(large) {
					margin-right: 40px;
				}

				&:hover {
					color: $amaranth;
				}

				&:last-child {
					margin-right: 0;
				}

				&.highlighted {
					font-weight: 900;
					border-bottom: 2px solid $amaranth;
					padding-bottom: 3px;
				}
			}
		}

		&--utility {
			//.header--nav--utility
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-content: center;
			align-items: center;
			flex: 0 0 auto;
			overflow: visible;

			&--btn {
				//.header--nav--utility--btn
				background: none;
				flex: 0 0 auto;
				padding: 0;
				text-align: center;
				color: $white;
				height: 20px;
				width: 20px;
				margin-right: 25px;
				overflow: visible;
				@include subtle(color);

				.icon {
					width: 20px;
					height: 20px;
				}

				@include breakpoint(large) {
					width: 24px;
					height: 24px;
					margin-right: 30px;

					.icon {
						width: 24px;
						height: 24px;
					}
				}

				svg {
					margin: 0 auto;
				}

				&:hover,
				&:focus {
					outline: none;
					color: $amaranth;
					background: none;
				}

				&:last-child {
					margin-right: 0;
				}
			}

			.menu-btn {
				&--icon {
					//.menu-btn--icon
					height: 11px;
				}

				&--label {
					//.menu-btn--label
					display: block;
					line-height: 1em;
					font-weight: normal;
					text-transform: uppercase;
					text-indent: 0;
					font-size: 7px;
					margin-top: 4px;

					@include breakpoint(large) {
						font-size: 9px;
						margin-top: 5px;
						text-indent: -1px;
					}
				}
			}
		}
	}

	&--menu {
		//.header--menu
		position: fixed;
		top: 0;
		bottom: 0;
		pointer-events: none;
		overflow-x: hidden;
		overflow-y: auto;
		left: 100%;
		width: 100%;
		background: $cod;

		@include subtle(all, 600ms);

		@include breakpoint(large) {
			background: none;
			right: 0;
			left: 0;
		}

		.close-btn {
			color: $white;
			background: none;
			position: absolute;
			top: 27px;
			right: 16px;
			line-height: 18px;
			width: 18px;
			height: 18px;
			padding: 0;
			@include subtle(color);

			.icon {
				width: 18px;
				height: 18px;
			}

			@include breakpoint(large) {
				top: 38px;
				right: 40px;
				line-height: 20px;
				width: 20px;
				height: 20px;
			}

			&:hover,
			&:focus {
				outline: none;
				background: none;
				color: $amaranth;
			}
		}

		&--left {
			//.header--menu--left
			display: block;
			background: $black;
			width: 100%;
			padding: 30px 50px 30px 50px;
			@include subtle(all, 600ms);

			@include breakpoint(large) {
				position: absolute;
				left: 100%;
				top: 0;
				bottom: 0;
				width: 275px;
				padding: 50px 50px 0 50px;
			}

			ul {
				display: block;
				list-style: none;
				margin: 0;
				padding: 0;

				@include breakpoint(large) {
					float: right;
					display: inline;
					clear: both;
				}
			}

			li {
				margin: 0 0 15px 0;
				padding: 0;

				&:last-child {
					margin-bottom: 0;
				}

				@include breakpoint(large) {
					margin-bottom: 30px;
				}
			}

			a {
				font-family: $sansSerif;
				font-weight: bold;
				font-style: normal;
				font-stretch: normal;
				font-size: 16px;
				line-height: 1em;
				letter-spacing: 0.8px;
				color: $white;
				text-decoration: none;
				@include subtle(color);

				&:hover,
				&:focus {
					outline: none;
					color: $amaranth;
				}

				@include breakpoint(large) {
					font-size: 18px;
				}
			}
		}

		&--right {
			//.header--menu--right
			background: $cod;
			display: block;
			width: 100%;
			@include subtle(all, 600ms);

			@include breakpoint(large) {
				padding: 50px;
				position: absolute;
				top: auto;
				top: 0;
				bottom: 0;
				left: 100%;
				width: calc(100% - 275px);
			}
		}

		&--sections {
			//.header--menu--sections
			display: block;

			@include breakpoint(large) {
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-start;
				align-content: stretch;
				align-items: stretch;
				margin-left: -10px;
				margin-right: -10px;
				width: calc(100% + 20px);
			}

			&--item {
				//.header--menu--sections--item
				display: block;
				width: 100%;
				border-bottom: 2px solid $black;

				@include breakpoint(large) {
					flex: 0 0 33.32%;
					width: 33.32%;
					border-bottom: none;
					padding: 0 10px;
				}

				&--heading {
					//.header--menu--sections--item--heading
					font-family: $sansSerif;
					font-size: 12px;
					font-weight: 900;
					font-style: normal;
					font-stretch: normal;
					line-height: normal;
					letter-spacing: 0.5px;
					color: $twine;
					text-transform: uppercase;
					padding: 15px 50px;
					margin: 0;
					position: relative;

					@include breakpoint(large) {
						padding: 0;
						margin: 0 0 10px 0;

						&::after {
							display: none;
						}
					}

					&::after {
						content: '+';
						position: absolute;
						top: 50%;
						margin-top: -5px;
						height: 10px;
						width: 10px;
						font-size: 16px;
						line-height: 8px;
						right: 20px;
					}
				}

				&--list {
					//.header--menu--sections--item--list
					margin: 0 0 0 0;
					padding: 0;
					list-style: none;
					display: none;
					padding: 5px 50px 20px 50px;

					@include breakpoint(large) {
						display: block;
						padding: 0;
						margin: 0 0 30px 0;
					}

					li {
						margin: 0 0 15px 0;
						padding: 0;

						&:last-child {
							margin-bottom: 0;
						}

						@include breakpoint(large) {
							margin: 0 0 5px 0;
						}
					}

					a {
						font-family: $sansSerif;
						font-size: 14px;
						font-weight: bold;
						font-style: normal;
						font-stretch: normal;
						line-height: 1em;
						letter-spacing: 0.5px;
						color: $white;
						text-decoration: none;
						@include subtle(color);

						&:hover,
						&:focus {
							outline: none;
							color: $amaranth;
						}
					}
				}

				&.open {
					.header--menu--sections--item--heading {
						&::after {
							content: '\2013';
						}
					}

					.header--menu--sections--item--list {
						display: block;
					}
				}
			}
		}

		&.open {
			pointer-events: all;
			left: 0;

			@include breakpoint(large) {
				.header--menu--right {
					left: 275px;
				}

				.header--menu--left {
					left: 0;
				}
			}
		}
	}
}
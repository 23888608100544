﻿.upcoming-performances--load-more--wrapper
{
    width: 100%;
}
.donors--details {
    background-color: transparent;
}
.spacer {
    display:block;
    padding-bottom: 6rem;
}

.rich-text-component ul {
    list-style-type: disc;
    font-family: "Sentinel SSm A", "Sentinel SSm B", Georgia, "Times New Roman", Times, serif;
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0;
    color: #000;
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 1.71em;

    @media print, screen and (min-width: 64em)
    {
        font-size: 16px;
        line-height: 1.63em;
    }
}
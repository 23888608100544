.hero {
    display: block;
    width: 100%;
    position: relative;
    height: 0;
    padding-bottom: 100%;

    @include breakpoint(medium) {
        padding-bottom: 66.66%;
    }

    @include breakpoint(large) {
        padding-bottom: 50.333%;
    }

    &.short {
        padding-bottom: 60%;
        min-height: 320px;

        @include breakpoint(medium) {
            padding-bottom: 40%;
            min-height: 380px;
        }

        @include breakpoint(large) {
            padding-bottom: 33%;
            min-height: 440px;
        }
        // .hero--image,
        // .hero--shade {
        // 	bottom: 210px;
        // }
        // .hero--subtitle {
        // 	color: $black;
        // }
        // .hero--actions {
        // 	.btn.link {
        // 		color: $black;
        // 		&:hover {
        // 			color: $amaranth;
        // 		}
        // 	}
        // }
    }


    &.utility {
        padding-bottom: 28%;
        min-height: 120px;

        @include breakpoint(medium) {
            padding-bottom: 20%;
            min-height: 120px;
        }

        @include breakpoint(large) {
            padding-bottom: 24%;
            min-height: 160px;
        }
    }

    &--image {
        //.hero--image
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        display: block;
    }

    &--shade {
        //.hero--shade
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(to bottom, rgba($black, 0.7), rgba($black, 0.55) 22%, rgba($black, 0) 46%, rgba($black, 0.6) 84%, rgba($black, 0.7));

        @include breakpoint(large) {
            background-image: linear-gradient(to bottom, rgba($black, 0.7), rgba($black, 0.15) 16%, rgba($black, 0) 46%, rgba($black, 0.6) 84%, rgba($black, 0.7));
        }
    }

    &--content {
        //.hero--content
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        color: $white;
        padding: 20px 16px;

        @include breakpoint(large) {
            padding: 50px 85px;
        }
    }

    &--supertitle {
        //.hero--supertitle
        font-size: 12px;
        line-height: 1.25em;
        background: $cod;
        display: inline-block;
        max-width: 70%;
        position: relative;
        padding: 9px 16px 7px 0;
        font-family: $serif;
        font-weight: 500;

        em {
            margin-left: 0.25em;
        }

        @include breakpoint(large) {
            padding: 12px 16px 10px 0;
            font-size: 16px;
            font-weight: bold
        }

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 100%;
            bottom: 0;
            background: $cod;
            width: 16px;

            @include breakpoint(large) {
                width: 85px;
            }
        }
    }

    &--title {
        //.hero--title
        display: inline-block;
        position: relative;
        background: $black;
        text-transform: uppercase;
        font-weight: bold;
        text-indent: -0.05em;
        font-size: 36px;
        line-height: 1.25em;
        max-width: 100%;
        padding: 10px 16px 10px 0;

        &.long {
            font-size: 18px;
        }

        &.longer {
            font-size: 11px;
        }

        @include breakpoint(medium) {
            font-size: 58px;

            &.long {
                font-size: 29px;
            }

            &.longer {
                font-size: 15px;
            }
        }

        @include breakpoint(large) {
            max-width: 75%;
            font-size: 80px;
            line-height: 1;
            padding: 20px 25px 18px 0;

            &.long {
                font-size: 40px;
            }

            &.longer {
                font-size: 20px;
            }
        }

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 100%;
            bottom: 0;
            background: $black;
            width: 16px;

            @include breakpoint(large) {
                width: 85px;
            }
        }
    }

    &--subtitle {
        //.hero--subtitle
        text-transform: uppercase;
        line-height: 1.63;
        display: block;
        margin-top: 15px;
        font-size: 12px;

        @include breakpoint(large) {
            font-size: 16px;
        }
    }

    &--actions {
        //.hero--actions
        display: block;
        margin-top: 15px;

        @include breakpoint(large) {
            margin-top: 20px;
        }

        .btn {
            & + .btn {
                margin-left: 15px;

                @include breakpoint(large) {
                    margin-left: 30px;
                }
            }
        }
    }
}

.events {
	@include pageSection;

	@include breakpoint(medium down) {
		padding-bottom: 0;
	}

	&--date {
		//.events--date
		margin-top: 0;
		margin-bottom: 0;

		&--title {
			//.events--date--title
			font-family: $sansSerif;
			font-size: 16px;
			font-weight: bold;
			line-height: 1;
			color: $black;
			text-transform: uppercase;
			margin: 0 0 20px 0;
		}

		&--list {
			//.events--date--list
			width: calc(100% + 40px);
			margin-left: -20px;
			margin-bottom: 20px;
			border-bottom: 1px solid $silver;

			@include breakpoint(large) {
				width: auto;
				margin-left: 0;
				margin-bottom: 0;
				border-bottom: none;
			}
		}

		&:last-child {
			.events--date--list {
				margin-bottom: 0;
			}
		}

		&--event {
			//.events--date--event
			display: flex;
			padding: 20px;
			justify-content: space-between;
			align-content: center;
			align-items: center;

			&:nth-child(odd) {
				background: $concrete;
			}

			&--content {
				//.events--date--event--content
				display: block;
				font-family: $sansSerif;
				color: $black;
				margin-bottom: 0;
			}

			&--supertitle {
				//.events--date--event--supertitle
				display: block;
				font-size: 10px;
				font-weight: 300;
				font-style: normal;
				line-height: 1;
				text-transform: uppercase;
				margin: 0 0 5px 0;
			}

			&--title {
				//.events--date--event--title
				display: block;
				font-weight: bold;
				font-size: 14px;
				line-height: 1;
				margin: 0 0 5px 0;

				@include breakpoint(large) {
					font-size: 16px;
				}
			}

			&--subtitle {
				//.events--date--event--subtitle
				display: block;
				font-size: 12px;
				line-height: 1.17em;
				font-weight: 300;
				font-style: normal;
				margin: 0;

				@include breakpoint(large) {
					font-size: 13px;
					line-height: 1;
				}
			}

			&--action {
				//.events--date--event--action
				display: block;
				padding-left: 20px;
				padding-right: 20px;
				margin: 0 0 0 15px;
			}
		}
	}
}
@font-face {
	font-family: "L Bell Gothic W01";
	font-style: normal;
	font-weight: 900;
	src: url("https://fast.fonts.net/dv2/14/7ca077cb-2514-4392-97b6-a340df2dfc55.woff2?d44f19a684109620e484167fa190e818e39e1343cfd10daa89b0c4f5b9c0f51bade70fdfc0814d6fa58f563b9830f1805201b2fa86b233043bd5d623233f1c8a01f9dac278bebe342b143da0b87ad16588491a6e9bb2ec5bd20f27a80d850ed71190d2a446d38207113dbbe03430454ec7cb632457d3fe6544604bf5970686c02fc9df4f0b059a01953b89a58a17a6c67c02908046522c13b2afa82407e3bbea549b928ab2c4ca2781171cc7c559da2e9d3b2b48f2de82ec5a624a5370a6fc108a6d38e82ccc1fc484b48aa79f7885c2215cc088fccbe692c8d41c7578970e540e1c0926c3700e61da8d6cf7d8a167daf0901953bdb239e3e7476dc18753ce679fbb1ba641c3e8415029&projectId=6f0cfca4-b033-46c3-974a-82be69d318f6") format('woff2');
}

@font-face {
	font-family: "L Bell Gothic W01";
	font-style: normal;
	font-weight: 700;
	src: url("https://fast.fonts.net/dv2/14/01e67234-6202-47bf-b379-cc84d6a3da95.woff2?d44f19a684109620e484167fa190e818e39e1343cfd10daa89b0c4f5b9c0f51bade70fdfc0814d6fa58f563b9830f1805201b2fa86b233043bd5d623233f1c8a01f9dac278bebe342b143da0b87ad16588491a6e9bb2ec5bd20f27a80d850ed71190d2a446d38207113dbbe03430454ec7cb632457d3fe6544604bf5970686c02fc9df4f0b059a01953b89a58a17a6c67c02908046522c13b2afa82407e3bbea549b928ab2c4ca2781171cc7c559da2e9d3b2b48f2de82ec5a624a5370a6fc108a6d38e82ccc1fc484b48aa79f7885c2215cc088fccbe692c8d41c7578970e540e1c0926c3700e61da8d6cf7d8a167daf0901953bdb239e3e7476dc18753ce679fbb1ba641c3e8415029&projectId=6f0cfca4-b033-46c3-974a-82be69d318f6") format('woff2');
}

@font-face {
	font-family: "L Bell Gothic W01";
	font-style: normal;
	font-weight: 400;
	src: url("https://fast.fonts.net/dv2/14/183be925-27b7-4b13-b957-6bdcd86b0631.woff2?d44f19a684109620e484167fa190e818e39e1343cfd10daa89b0c4f5b9c0f51bade70fdfc0814d6fa58f563b9830f1805201b2fa86b233043bd5d623233f1c8a01f9dac278bebe342b143da0b87ad16588491a6e9bb2ec5bd20f27a80d850ed71190d2a446d38207113dbbe03430454ec7cb632457d3fe6544604bf5970686c02fc9df4f0b059a01953b89a58a17a6c67c02908046522c13b2afa82407e3bbea549b928ab2c4ca2781171cc7c559da2e9d3b2b48f2de82ec5a624a5370a6fc108a6d38e82ccc1fc484b48aa79f7885c2215cc088fccbe692c8d41c7578970e540e1c0926c3700e61da8d6cf7d8a167daf0901953bdb239e3e7476dc18753ce679fbb1ba641c3e8415029&projectId=6f0cfca4-b033-46c3-974a-82be69d318f6") format('woff2');
}

$sansSerif: "L Bell Gothic W01",
Arial,
Helvetica,
sans-serif;
$serif: "Sentinel SSm A",
"Sentinel SSm B",
Georgia,
'Times New Roman',
Times,
serif;
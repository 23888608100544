﻿.top-search {
    background: $header-grey;
    width: 100%;
    padding: 1rem 0;
    display: none;
    position: relative;

    @include breakpoint(1024 down) {
        z-index: 999999;
    }

    .holder {
        float: left;
        width: 100%;
        position: relative;

        .search-icon {
            outline: none;
            border: none;
            background: url('/content/images/svg/sprite.symbol.svg#search');
            height: 1.875rem;
            width: 1.875rem;
            position: absolute;
            left: 0;
            top: 0;
            background-size: 100% 100% !important;
        }

        .search-box {
            float: left;
            width: 100%;
            border: none !important;
            outline: none !important;
            box-shadow: none !important;
            background: none;
            font-size: 1rem;
            color: $header-search-box;
            font-family: $sansSerif;
            margin-bottom: 0;
            padding: 0 0 .5rem 3rem;

            @include breakpoint (large up) {
                font-size: 1.5rem;
                line-height: 1.5rem;
            }
        }

        ::-webkit-input-placeholder {
            color: $header-search-box-ph;
            text-transform: uppercase;
            font-family: $sansSerif;
            font-size: 1rem;
            line-height: 1.5rem;
        }

        :-moz-placeholder { /* Firefox 18- */
            color: $header-search-box-ph;
            text-transform: uppercase;
            font-family: $sansSerif;
            font-size: 1rem;
            line-height: 1.5rem;
        }

        ::-moz-placeholder { /* Firefox 19+ */
            color: $header-search-box-ph;
            text-transform: uppercase;
            font-family: $sansSerif;
            font-size: 1rem;
            line-height: 1.5rem;
        }

        :-ms-input-placeholder {
            color: $header-search-box-ph;
            text-transform: uppercase;
            font-family: $sansSerif;
            font-size: 1rem;
            line-height: 1.5rem;
        }

        /*&:after {
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: transparent;
            border-top-color: $header-grey;
            border-width: 45px;
            margin-left: -45px;
            right: -2rem;
            bottom: -6rem;
            opacity: 0;
            z-index: 9999;
            @include trans-all();

            @include breakpoint(768 down) {
                display: none;
            }
        }*/
    }

    &.open {
        .holder {
            &:after {
                opacity: 1;
            }
        }
    }
}
.top-search ~ .umb-grid {
    position: relative;
    display: block;
}
.search-component {
    margin-top: 4rem;
    margin-bottom: 4rem;

    .search-bar {
        float: left;
        width: 100%;
        position: relative;
    }

    .search-icon {
        outline: none;
        border: none;
        background: url('/content/images/svg/sprite.symbol.svg#search');
        height: 1.875rem;
        width: 1.875rem;
        position: absolute;
        left: 0;
        top: 0;
        background-size: 100% 100% !important;
        color: $darkGrey;
    }

    .search-box {
        float: left;
        width: 100%;
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
        background: none;
        font-size: 1rem;
        color: #000;
        font-family: $sansSerif;
        margin-bottom: 0;
        padding: 0 0 .5rem 3rem;
        text-align: left;

        @include breakpoint (large up) {
            font-size: 1.5rem;
            line-height: 1.5rem;
        }
    }

    ::-webkit-input-placeholder {
        color: $header-search-box-ph;
        text-transform: uppercase;
        font-family: $sansSerif;
        font-size: 1rem;
        line-height: 1.5rem;
    }

    :-moz-placeholder { /* Firefox 18- */
        color: $header-search-box-ph;
        text-transform: uppercase;
        font-family: $sansSerif;
        font-size: 1rem;
        line-height: 1.5rem;
    }

    ::-moz-placeholder { /* Firefox 19+ */
        color: $header-search-box-ph;
        text-transform: uppercase;
        font-family: $sansSerif;
        font-size: 1rem;
        line-height: 1.5rem;
    }

    :-ms-input-placeholder {
        color: $header-search-box-ph;
        text-transform: uppercase;
        font-family: $sansSerif;
        font-size: 1rem;
        line-height: 1.5rem;
    }

    .search-results {
        color: #222;
        float: left;
        padding: 1.5em;
        background-color: transparent;
        margin: 1em 0;
        width: 100%;
        border-top: 6px solid $red;
        border-radius: 3px;
    }

    .search-results .search-result-desc {
        font-size: 1.2em;
        margin: 0 0 0.8em 0;
    }

    .search-results .search-spelling {
    }

    .search-results .search-spelling a {
    }

    .search-results .search-spelling-original {
    }

    .search-results .search-spelling-original a {
    }

    .search-results .search-result {
        margin: 0 0 1.25rem 0;
    }

    .search-results .search-title {
        font-size: 1.4rem;
        font-weight: 400;
    }

    .search-results .search-title a {
        /*color: #0b4f8a;*/
        font-size: 1.2rem;
        font-weight: 500;
    }

    .search-results .search-title a:visited {
        /*color: #609;*/
    }

    .search-results .search-snippet {
        margin-top: 0.5rem;
        font-size: 1rem;
    }

    .search-results .search-url {
        font-size: 0.75rem;
    }
}
.row {
	padding-left: 10px;
	padding-right: 10px;
}

$spaces: (before: 'margin-top', above: 'padding-top', below: 'padding-bottom', after: 'margin-bottom');
$sizes: (none: 0, tn: 10px, sm: 20px, md: 30px, lg: 40px, xl: 50px);

.space {

	@each $pos,
	$prop in $spaces {

		@each $size,
		$value in $sizes {
			&-#{$pos}-#{$size} {
				#{$prop}: $value;
			}
		}
	}
}

.show-for-home {
	display: none;

	.home & {
		display: block;
	}
}

.wrapper {
	// position: relative;
	// overflow-x: hidden;

	&::before {
		content: "";
		display: block;
		position: fixed;
		z-index: -1;
		background: $pearl;
		top: 0;
		left: -50%;
		bottom: 0;
		right: 0;
		width: 100%;
		transform: skew(15deg);
	}
}

.larr {
	display: inline-block;
	vertical-align: middle;
	margin: 0;
	width: 10px;
	height: 10px;
	transform: rotate(45deg);
	border-bottom: solid 2px $twine;
	border-left: solid 2px $twine;
}

.rarr {
	display: inline-block;
	vertical-align: middle;
	margin: 0;
	width: 10px;
	height: 10px;
	transform: rotate(45deg);
	border-top: solid 2px $twine;
	border-right: solid 2px $twine;
}

a {
	display: inline-block;
	text-decoration: underline;
	font-family: $sansSerif;
	font-size: 16px;
	font-weight: bold;
	font-style: normal;
	line-height: 1em;
	letter-spacing: 0;
	color: $black;
	@include subtle(color);

	&:focus,
	&:hover {
		color: $amaranth;
	}
}
// Adding wufoo styles here
.wufoo {
    input[type="checkbox"], input[type="radio"] {
        float: left !important;
    }

    .field:not([type="checkbox"]):not([type="radio"]) {
        padding: 0 10px !important;
        min-height: 30px !important;
        height: 30px !important;
    }

    .field.textarea:not([type="checkbox"]):not([type="radio"]) {
        height: 200px !important;
        min-height: 200px !important;
        padding: 10px !important;
    }
}

.safari {
    .wufoo label.choice {
        margin-top: 0 !important;
    }
}
.video {
	&-player {
		//.video-player
		display: block;
		width: 100%;
		height: 0;
		padding-bottom: 56.25%;
		position: relative;

		&--frame {
			//.video-player--frame
			@include pageSection();
			background: $cod;
			position: relative;
			overflow: hidden;

			&::before {
				content: "";
				background: $mediumGrey;
				opacity: 0.5;
				position: absolute;
				top: 0;
				right: -33%;
				bottom: 0;
				width: 50%;
				transform: skew(15deg);

			}
		}

		&--video {
			//.video-player--video
			display: block;
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
	}
}
@mixin formfield() {
	border: 1px solid $black;
	color: $black;
	font-family: $serif;
	font-size: 16px;
	line-height: 16px;
	font-weight: 500;
	background: $white;
	min-height: 40px;

	@include breakpoint(large) {
		font-size: 14px;
		line-height: 14px;
	}

	&:focus {
		outline: none;
		box-shadow: 0 0 10px -3px $amaranth;
	}
}

select,
input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"],
textarea {
	@include formfield();
}

@mixin selectbox() {
	cursor: pointer;
	background: url("data:image/svg+xml;charset=UTF-8,%3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='34' height='9' viewBox='0 0 34 9'%3e%3cpolygon fill='%23C29C5E' points='7.01,9.03 0.08,2.1 1.84,0.33 7.01,5.5 12.17,0.33 13.94,2.1 '/%3e%3c/svg%3e ") right center no-repeat $white;
}

select {
	@include selectbox();
}

@mixin fieldlabel() {
	font-family: $sansSerif;
	font-size: 12px;
	font-weight: 900;
	font-style: normal;
	line-height: 12px;
	letter-spacing: 0.5px;
	color: $twine;
	text-transform: uppercase;
	display: block;
	margin: 0 0 10px 0;

	&[for] {
		cursor: pointer;
	}
}

label {
	@include fieldlabel();
}
.media {
	@include pageSection();

	&--cards {
		//.media--cards
		// (the wrapper)

		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-content: stretch;
		align-items: stretch;
		margin: -2px 0 2px -2px;
		width: calc(100% + 2px);
	}

	&--card {
		//.media--card
		position: relative;
		font-family: $sansSerif;
		display: block;
		flex: 0 1 100%;
		margin: 0;
		padding: 2px 0 0 2px;
		max-width: 100%;

		@include breakpoint(large) {
			flex-basis: 33.32%;
			max-width: 33.32%;
		}

		&--image {
			//.media--card--image
			position: relative;
			height: 0;
			padding-bottom: 100%;
			width: 100%;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
		}

		&--shade {
			//.media--card--shade
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-image: linear-gradient(to bottom, rgba($black, 0), rgba($black, 0.1) 63%, $black);
		}

		&--content {
			//.media--card--content
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			padding: 20px;
			display: flex;
			justify-content: space-between;
			align-content: flex-end;
			align-items: flex-end;
			flex-wrap: nowrap;
		}

		&--text {
			//.media--card--text
			flex: 1 1 auto;
		}

		&--title {
			//.media--card--title
			font-size: 20px;
			font-weight: bold;
			font-style: normal;
			line-height: 1.3em;
			letter-spacing: 0;
			color: $white;
			text-transform: uppercase;
		}

		&--subtitle {
			//.media--card--subtitle
			font-size: 12px;
			font-weight: 900;
			font-style: normal;
			line-height: 2em;
			letter-spacing: 0.5px;
			color: $twine;
			text-transform: uppercase;
		}

		&--actions {
			//.media--card--actions
			flex: 0 0 auto;
			padding: 10px 10px 10px 20px;

			.download-btn {
				color: $white;
				@include subtle(color);

				svg {
					width: 30px;
					height: 30px;
					@include subtle(fill);
				}

				&:hover,
				&:focus {
					color: $amaranth;
				}
			}
		}
	}
}
.highlights {
	@include pageSection;

	@include breakpoint(medium down) {
		padding-bottom: 0;
	}

	&--list {
		//.highlights--list
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-content: stretch;
		align-items: stretch;
		margin: -2px 0 2px -2px;
		width: calc(100% + 2px);

		&--item {
			//.highlights--list--item
			display: block;
			flex: 0 1 100%;
			margin: 0;
			padding: 2px 0 0 2px;
			max-width: 100%;

			@include breakpoint(medium) {
				flex-basis: 50%;
				max-width: 50%;
			}

			@include breakpoint(large) {
				flex-basis: 33.32%;
				max-width: 33.32%;
			}

			.home & {
				&:nth-child(n+4) {
					@include breakpoint(small only) {
						display: none;
					}
				}
			}
		}
	}

	&.centered {
		.highlights--list {
			justify-content: center;
		}
	}

	&--card {
		//.highlights--card
		display: block;
		height: 0;
		padding-bottom: 66.37%;
		position: relative;

		&--image {
			//.highlights--card--image
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
		}

		&--shade {
			//.highlights--card--shade
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-image: linear-gradient(to bottom, rgba($black, 0), rgba($black, 0.3) 55%, $black);

			@include breakpoint(large) {}
		}

		&--content {
			//.highlights--card--content
			position: absolute;
			bottom: 0;
			right: 0;
			left: 0;
			padding: 15px;
		}

		&--title {
			//.highlights--card--title
			display: block;
			font-family: $sansSerif;
			font-size: 20px;
			font-weight: bold;
			font-style: normal;
			line-height: 1.3em;
			color: $white;
			text-transform: uppercase;
		}

		&--subtitle {
			//.highlights--card--subtitle
			display: block;
			font-family: $sansSerif;
			font-size: 12px;
			font-weight: 900;
			font-style: normal;
			line-height: 2em;
			letter-spacing: 0.5px;
			color: $twine;
			text-transform: uppercase;
		}
	}
}
.blog-post-nav {
	@include pageSection();
	padding-bottom: 0;

	@include breakpoint(large) {
		padding-bottom: 0;
	}

	a {
		font-family: $serif;
		font-size: 12px;
		font-weight: normal;
		font-style: normal;
		line-height: 1em;
		letter-spacing: 0;
		color: $black;
		display: block;
		margin-top: 24px;
		@include subtle(all);

		.larr,
		.rarr {
			@include subtle(border-color);
		}

		&:focus,
		&:hover {
			color: $amaranth;

			.larr,
			.rarr {
				border-color: $amaranth;
			}
		}

	}

	.row {
		min-height: 60px;
		position: relative;
	}

	.column:first-child::after {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 50%;
		margin-left: -0.5px;
		border-left: 1px solid $pearl;
	}
}
// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

////
/// @group accordion
////

/// Default background color of an accordion group.
/// @type Color
$accordion-background: $white !default;

/// If `true`, adds plus and minus icons to the side of each accordion title.
/// @type Boolean
$accordion-plusminus: true !default;

/// Font size of accordion titles.
/// @type Number
$accordion-title-font-size: rem-calc(12) !default;

/// Default text color for items in a Menu.
/// @type Color
$accordion-item-color: foreground($accordion-background, $primary-color) !default;

/// Default background color on hover for items in a Menu.
/// @type Color
$accordion-item-background-hover: $light-gray !default;

/// Default padding of an accordion item.
/// @type Number | List
$accordion-item-padding: 1.25rem 1rem !default;

/// Default background color of tab content.
/// @type Color
$accordion-content-background: $white !default;

/// Default border color of tab content.
/// @type Color
$accordion-content-border: 1px solid $light-gray !default;

/// Default text color of tab content.
/// @type Color
$accordion-content-color: foreground($accordion-content-background, $body-font-color) !default;

/// Default padding for tab content.
/// @type Number | List
$accordion-content-padding: 1rem !default;

/// Adds styles for an accordion container. Apply this to the same element that gets `data-accordion`.
@mixin accordion-container {
  margin-#{$global-left}: 0;
  background: $accordion-background;
  list-style-type: none;
}

/// Adds styles for the accordion item. Apply this to the list item within an accordion ul.
@mixin accordion-item {
  &:first-child > :first-child {
    border-radius: $global-radius $global-radius 0 0;
  }

  &:last-child > :last-child {
    border-radius: 0 0 $global-radius $global-radius;
  }
}

/// Adds styles for the title of an accordion item. Apply this to the link within an accordion item.
@mixin accordion-title {
  position: relative;
  display: block;
  padding: $accordion-item-padding;

  border: $accordion-content-border;
  border-bottom: 0;

  font-size: $accordion-title-font-size;
  line-height: 1;
  color: $accordion-item-color;

  :last-child:not(.is-active) > & {
    border-bottom: $accordion-content-border;
    border-radius: 0 0 $global-radius $global-radius;
  }

  &:hover,
  &:focus {
    background-color: $accordion-item-background-hover;
  }

  @if $accordion-plusminus {
    &::before {
      position: absolute;
      top: 50%;
      #{$global-right}: 1rem;
      margin-top: -0.5rem;
      content: '+';
    }

    .is-active > &::before {
      content: '–';
    }
  }
}

/// Adds styles for accordion content. Apply this to the content pane below an accordion item's title.
@mixin accordion-content {
  display: none;
  padding: $accordion-content-padding;

  border: $accordion-content-border;
  border-bottom: 0;
  background-color: $accordion-content-background;

  color: $accordion-content-color;

  :last-child > &:last-child {
    border-bottom: $accordion-content-border;
  }
}

@mixin foundation-accordion {
  .accordion {
    @include accordion-container;
  }

  .accordion-item {
    @include accordion-item;
  }

  .accordion-title {
    @include accordion-title;
  }

  .accordion-content {
    @include accordion-content;
  }
}

.footer {
	background: $cod;
	color: $white;
	font-family: $serif;
	text-align: left;

	a {
		color: $white;
		font-weight: bold;
		text-decoration: none;
		@include subtle(color);

		&:hover {
			color: $amaranth;
		}
	}

	&--top {
		//.footer--top
		border-bottom: 2px solid $black;
		text-align: center;

		@include breakpoint(large) {
			text-align: left;
		}

		.column {
			padding-top: 15px;
			padding-bottom: 15px;

			@include breakpoint(large) {
				padding-top: 30px;
				padding-bottom: 30px;
			}
		}

		.box-office {
			p {
				font-size: 14px;
				font-weight: 500;
				font-style: normal;
				line-height: 1.29em;
				letter-spacing: 0.5px;
				margin-bottom: 10px;
				color: $white;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		.phone-link {
			color: $white;
			font-family: $sansSerif;
			font-size: 18px;
			font-weight: 900;
			font-style: normal;
			line-height: 1;
			letter-spacing: 0.7px;
			display: inline-block;
			margin-bottom: 10px;
			@include subtle(color);

			&:hover {
				color: $amaranth;
			}
		}

		.social-links {
			&--list {
				//.social-links--list
				list-style: none;
				margin: 0;
				padding: 0;

				@include breakpoint(large) {
					margin-top: 22px;
				}

				&--item {
					//.social-links--list--item
					display: inline-block;
					margin: 0 15px 15px 0;

					.social-link {
						display: block;
						text-align: center;
						line-height: 35px;
						width: 40px;
						height: 40px;
						background: none;
						border: 1px solid $amaranth;
						border-radius: 40px;
						@include subtle(background);

						.icon {
							width: 16px;
							height: 16px;
							margin: 0 auto;
						}

						&:hover {
							background: $amaranth;
						}

					}
				}
			}
		}
	}

	&--bottom {
		//.footer--bottom
		padding-top: 15px;
		padding-bottom: 15px;

		@include breakpoint(large) {
			padding-top: 0;
			padding-bottom: 0;
		}

		.column {
			padding-top: 15px;
			padding-bottom: 15px;

			@include breakpoint(large) {
				padding-top: 40px;
				padding-bottom: 40px;
			}
		}
	}

	&--left {
		//.footer--left
		position: relative;

		&::after {
			content: "";
			display: none;

			@include breakpoint(large) {
				display: block;
				position: absolute;
				top: 0;
				left: 100%;
				margin-left: 10px;
				bottom: 0;
				border-right: 2px solid $black;
			}
		}
	}

	&--right {
		//.footer--right
		position: relative;
	}

	&--contact {
		//.footer--contact
		address {
			font-size: 14px;
			font-weight: 500;
			font-style: normal;
			line-height: 1.29em;
			letter-spacing: 0.5px;
		}
	}

	&--copyright {
		//.footer--copyright
		position: absolute;
		left: 10px;
		bottom: 15px;
		right: 10px;
		font-size: 12px;
		font-weight: 500;
		font-style: normal;
		line-height: 1.5em;
		letter-spacing: 0.5px;
		text-align: center;

		@include breakpoint(large) {
			bottom: 40px;
			text-align: left;
		}
	}

	&--menu {
		//.footer--menu
		display: flex;
		justify-content: flex-start;
		align-content: stretch;
		align-items: stretch;
		flex-wrap: wrap;
		margin-right: -10px;
		margin-bottom: 40px;
		margin-left: -10px;
		width: calc(100% + 20px);


		@include breakpoint(large) {
			margin-bottom: 0;
		}

		&--category {
			//.footer--menu--category
			display: block;
			padding-left: 10px;
			padding-right: 10px;
			max-width: 50%;
			flex: 1 1 50%;

			@include breakpoint(medium) {
				max-width: 33%;
				flex: 1 1 33%;
			}
		}

		&--list {
			//.footer--menu--list
			list-style: none;
			margin: 0 0 30px 0;
			padding: 0;
			font-family: $sansSerif;

			li {
				margin: 0 0 10px 0;
				font-size: 14px;
				line-height: 1em;
				letter-spacing: 0.5px;
			}
		}
	}

	h4 {
		font-size: 12px;
		font-weight: 900;
		line-height: 1;
		letter-spacing: 0.5px;
		text-indent: -0.05em;
		text-transform: uppercase;
		color: $twine;
		margin: 0 0 20px 0;
		font-family: $sansSerif;

		@include breakpoint(large) {
			margin-bottom: 10px;
		}
	}

	.mailing-list {
		&--form {
			//.mailing-list--form
			display: flex;
			justify-content: space-between;
			align-content: flex-start;
			align-items: flex-start;
			flex-wrap: nowrap;
		}

		&--email {
			//.mailing-list--email
			flex: 1 1 0px;
			border: none;
			background: $white;
			font-family: $serif;
			font-size: 14px;
			line-height: 1;
			color: $cod;

			%placeholder {
				font-family: $serif;
				font-size: 14px;
				font-weight: normal;
				font-style: normal;
				line-height: 1;
				letter-spacing: normal;
				color: $chalice;
			}

			&::placeholder {
				@extend %placeholder;
			}

			&::-webkit-placeholder {
				@extend %placeholder;

			}
		}

		&--submit {
			//.mailing-list--submit
			flex: 0 0 auto;
			background: none;
			transition: 333ms ease-in-out color;

			&:hover {
				color: $amaranth;
				background: none;
			}
		}
	}
}

.feature-list {
	@include pageSection();

	&--filters {
		//.feature-list--filters

		h5 {
			font-size: 14px;
			line-height: 1em;
			font-style: italic;
			font-weight: normal;
			font-family: $serif;
			margin: 0 0 15px 0;

			@include breakpoint(large) {
				font-size: 18px;
				margin-bottom: 20px;
			}
		}

		&--filter-row {
			//.feature-list--filters--filter-row
			padding-left: 0;
			padding-right: 0;

			.column {
				position: relative;
				margin-top: 20px;

				&:first-child {
					margin-top: 0;
				}

				@include breakpoint(medium) {
					margin-top: 0;
				}
			}
		}

		&--filter {
			//.feature-list--filters--filter
			margin: 0;
		}

		&--divider {
			//.feature-list--filters--divider
			position: absolute;
			border-top: 1px solid $amaranth;
			top: 50%;
			left: 10px;
			right: 10px;
			margin-top: -0.5px;
		}
	}

	&--item {
		//.feature-list--item
		@include pageSection();

		&--image {
			//.feature-list--item--image

			img {
				display: block;
				width: 250px;
				height: auto;
				max-width: none;
				margin: 0 0 20px 0;

				@include breakpoint(medium) {
					width: 100%;
					margin-bottom: 0;
				}
			}
		}

		&--content {
			//.feature-list--item--content
			font-family: $sansSerif;
			color: $black;
			padding: 0;

			@include breakpoint(large) {
				padding-left: 20px;
			}
		}

		&--title {
			//.feature-list--item--title
			font-weight: bold;
			font-size: 24px;
			line-height: 1.2em;

			@include breakpoint(large) {
				font-size: 30px;
			}
		}

		&--subtitle {
			//.feature-list--item--subtitle
			font-family: $serif;
			font-size: 14px;
			line-height: 1.44em;
			font-style: italic;
			font-weight: normal;
			margin: 10px 0 0 0;

			@include breakpoint(large) {
				font-size: 18px;
				margin-top: 15px;
			}
		}

		&--dates {
			//.feature-list--item--dates
			color: $amaranth;
			text-transform: uppercase;
			font-weight: 900;
			font-size: 14px;
			line-height: 1em;
			margin: 20px 0 0 0;

			@include breakpoint(large) {
				font-size: 18px;
				margin-top: 40px;
			}
		}

		&--location {
			//.feature-list--item--location
			font-size: 14px;
			line-height: 1em;
			font-weight: bold;
			margin: 10px 0 0 0;

			@include breakpoint(large) {
				font-size: 18px;
			}
		}

		&--series {
			//.feature-list--item--series
			font-family: $serif;
			font-size: 14px;
			line-height: 1em;
			margin: 10px 0 0 0;

			@include breakpoint(large) {
				font-size: 18px;
				margin-top: 15px;
			}
		}

		&--summary {
			//.feature-list--item--summary
			font-family: $serif;
			font-size: 13px;
			line-height: 1.63em;
			text-align: justify;
			margin: 20px 0 0 0;

			@include breakpoint(large) {
				font-size: 16px;
				margin-top: 40px;
			}
		}

		&--actions {
			//.feature-list--item--actions
			margin: 20px 0 0 0;

			@include breakpoint(large) {
				margin-top: 40px;
			}
		}
	}
}
.donate-bar {
	background: $cod;
	padding-top: 10px;
	padding-bottom: 20px;

	@include breakpoint(large) {
		padding-top: 20px;
	}

	label {
		margin: 0 0 5px 0;

		@include breakpoint(large) {
			margin: 0 0 10px 0;
		}
	}

	&--fund-select {
		//.donate-bar--fund-select

		@include breakpoint(medium) {
			margin-bottom: 0;
		}
	}

	&--dollar-field {
		//.donate-bar--dollar-field
		margin-bottom: 0;
	}

	&+.buffer {
		display: none;
	}

	&.pinned {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 10;

		+.buffer {
			display: block;
		}
	}
}
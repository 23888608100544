.callouts {
	background: $concrete;
	@include pageSection;

	@include breakpoint(medium down) {
		padding-bottom: 0;
	}

	&--card {
		//.callouts--card
		margin-left: -20px;
		width: calc(100% + 40px);

		@include breakpoint(medium only) {
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-content: flex-start;
			align-items: flex-start;
		}

		@include breakpoint(large) {
			margin-left: 0;
			width: auto;
		}

		&--image {
			//.callouts--card--image
			display: block;
			width: 100%;
			height: 0;
			padding-bottom: 56.34%;
			position: relative;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			margin: 0 0 30px 0;

			@include breakpoint(medium only) {
				padding-bottom: 28.17%;
				flex: 0 0 50%;
			}

			@include breakpoint(large) {
				margin-bottom: 35px;
			}
		}

		&--content {
			//.callouts--card--content
			padding-left: 75px;
			padding-right: 20px;

			@include breakpoint(medium only) {
				flex: 0 0 50%;
			}

			@include breakpoint(large) {
				padding-left: 175px;
			}
		}

		&--title {
			//.callouts--card--title
			position: relative;
			font-family: $sansSerif;
			font-weight: bold;
			font-style: normal;
			font-size: 24px;
			line-height: 1em;
			letter-spacing: normal;
			color: $black;
			text-transform: none;
			margin: 0 0 20px 0;

			@include breakpoint(large) {
				font-size: 30px;
				margin-bottom: 30px;
			}

			&::before {
				content: "";
				display: block;
				position: absolute;
				height: 0;
				border-top: 2px solid $amaranth;
				top: 100%;
				width: auto;
				top: 50%;
				right: 100%;
				margin-right: 10px;
				width: 55px;

				@include breakpoint(large) {
					width: 135px;
					margin-right: 20px;
				}
			}
		}

		&--summary {
			//.callouts--card--summary
			font-family: $serif;
			font-weight: normal;
			font-style: normal;
			letter-spacing: 0;
			color: $black;
			font-size: 14px;
			line-height: 1.71em;

			@include breakpoint(large) {
				font-size: 18px;
				line-height: 1.56em;

			}
		}

		&--action {
			//.callouts--card--actions
			padding-bottom: 30px;

			@include breakpoint(large) {
				padding-bottom: 50px;
			}
		}
	}
}
.parallax {
	&-set {
		//.parallax-set
		@include subtle(all, 500ms);
	}

	&-below {
		//.parallax-below
		opacity: 0;
		transform: translateY(50px) translateZ(0);
	}

	&-was-on {
		//.parallax-was-on
		opacity: 1;
		transform: translateY(0) translateZ(0);
	}

	&-on {
		//.parallax-on
		// @for $i from 0 through 3 {
		// 	&:nth-child(n + #{$i}) {
		// 		transition-delay: #{$i * 100ms};
		// 	}
		// }
	}

	&-outer {
		.row>& {
			display: flex;
			width: 100%;
			flex-wrap: wrap;
		}
	}
}
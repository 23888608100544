@mixin link($important:"") {
	background: none #{$important};
	color: $black #{$important};
	padding: 5px 0 3px 0 #{$important};
	position: relative;
	text-decoration: none;

	&::before,
	&::after {
		content: "";
		display: block;
		border-bottom: 2px solid $amaranth;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		@include subtle(all, 100ms, #{$important});
	}

	&::after {
		width: 0%;
		bottom: auto;
		top: 2px; // was 0
	}

	&.large::after {
		top: 0;
	}

	&:hover,
	&:focus {
		color: $black #{$important};
		background: none #{$important};

		&::after {
			width: 100%;
		}
	}

	&.light {
		color: $white #{$important};

		&:hover,
		&:focus {
			color: $white #{$important};
		}
	}
}

@mixin button($important:"", $link:false) {
	display: inline-block;
	background: $amaranth #{$important};
	color: $white #{$important};
	font-weight: bold #{$important};
	font-size: 14px;
	line-height: 1;
	padding: 13px 9px #{$important};
	text-transform: uppercase;
	vertical-align: middle;
	font-family: $sansSerif #{$important};
	text-decoration: none;
	@include subtle(background, 100ms, #{$important});

	@if $link==true {
		@include link(#{$important});
	}

	&.large {
		@include breakpoint(large) {
			font-size: 18px;
		}
	}

	&.link {
		@include link(#{$important});
	}

	&:hover,
	&:focus {
		color: $white #{$important};
		background: #C03345 #{$important};
	}

	&.full {
		display: block;
		width: 100%;
	}
}

button,
.btn,
.button,
input[type="submit"] {
	// @extend %button;
	@include button();
}
////
/// @group accordion-menu
////

/// Sets if accordion menus have the default arrow styles.
/// @type Boolean
$accordionmenu-arrows: true !default;

/// Sets accordion menu arrow color if arrow is used.
/// @type Color
$accordionmenu-arrow-color: $primary-color !default;

@mixin foundation-accordion-menu {
  .tree {
    .is-accordion-submenu-none > a {
      padding: 0;
      line-height: $list-lineheight;
    }

  }

  @if $accordionmenu-arrows {
    .is-accordion-submenu-parent > span {
      position: relative;
      display: block;
      color: $anchor-color;
      cursor: pointer;

      &:hover {
        color: $anchor-color-hover;
      }

      &::after {
        @include css-triangle(6px, $accordionmenu-arrow-color, down);
        position: absolute;
        top: 50%;
        right: 1rem;
        #{$global-right}: -4px;
      }

    }

    .is-accordion-submenu-parent[aria-expanded='true'] > span::after {
      transform: scaleY(-1);
      transform-origin: 50% 50%;
    }

  }
}

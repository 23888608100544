.donors {
	@include pageSection();

	&--options {
		//.donors--options
		display: flex;
		justify-content: center;
		margin-top: -30px;
		padding: 0 0 20px 0;
		position: relative;

		@include breakpoint(large) {
			padding-top: 30px;
			padding-bottom: 30px;
		}

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			display: block;
			width: 60px;
			z-index: 2;
			pointer-events: none;
			background-image: radial-gradient(ellipse 20% 55% at left center, rgba($scorpion, 0.15), rgba($scorpion, 0));
			opacity: 0;
			@include subtle(opacity);
		}

		&::after {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			display: block;
			width: 60px;
			z-index: 2;
			pointer-events: none;
			background-image: radial-gradient(ellipse 20% 55% at right center, rgba($scorpion, 0.15), rgba($scorpion, 0));
			opacity: 0;
			@include subtle(opacity);
		}

		&--wrapper {
			//.donors--options--wrapper
			position: relative;
			overflow: hidden;
			width: calc(100% + 40px);
			margin: 0 -20px 20px -20px;

			@include breakpoint(large) {
				width: 100%;
				margin-left: 0;
				margin-right: 0;
			}
		}

		&~.prev-btn {
			position: absolute;
			left: 0;
			top: 50%;
			width: 30px;
			height: 30px;
			background: $amaranth;
			color: $white;
			padding: 0;
			margin: -15px 0 0 0;
			z-index: 1;
			opacity: 0;
			@include subtle(all);

			.larr {
				display: block;
				position: absolute;
				top: 50%;
				right: 50%;
				margin: -5px -6.5px 0 0;
				width: 10px;
				height: 10px;
				transform: rotate(45deg);
				border-bottom: solid 2px $white;
				border-left: solid 2px $white;
			}

			&:hover,
			&:focus {
				outline: none;
				background: $black;
			}
		}

		&~.next-btn {
			position: absolute;
			right: 0;
			top: 50%;
			width: 30px;
			height: 30px;
			background: $amaranth;
			color: $white;
			padding: 0;
			margin: -15px 0 0 0;
			z-index: 1;
			opacity: 0;
			@include subtle(all);

			.rarr {
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				margin: -5px 0 0 -6.5px;
				width: 10px;
				height: 10px;
				transform: rotate(45deg);
				border-top: solid 2px $white;
				border-right: solid 2px $white;
			}

			&:hover,
			&:focus {
				outline: none;
				background: $black;
			}
		}

		&:not(.slidable) {
			padding-left: 0 !important;

			.donors--list {
				left: auto !important;
			}
		}

		&.slidable {
			overflow: hidden;
			justify-content: flex-start;
			margin: 0 30px;

			&::before,
			&::after,
			&~.prev-btn,
			&~.next-btn {
				opacity: 1;
			}

			&.start {

				&::before,
				&~.prev-btn {
					opacity: 0;
					pointer-events: none;
				}
			}

			&.end {

				&::after,
				&~.next-btn {
					opacity: 0;
					pointer-events: none;
				}
			}

			.donors--list {
				@include subtle(left, 500ms);
			}
		}
	}

	&--list {
		//.donors--list
		position: relative;
		margin-bottom: 30px;
		flex: 0 0 auto;
		display: flex;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-content: flex-end;
		align-items: flex-end;

		&::before {
			content: "";
			display: block;
			border-top: 1px solid $black;
			position: absolute;
			right: 88px;
			left: 88px;
			bottom: 10px;

			@include breakpoint(large) {
				bottom: 15px;
			}
		}
	}

	&--level {
		//.donors--level
		width: 175px;
		margin-right: 20px;

		&:last-child {
			margin-right: 0;
		}

		&--label {
			//.donors--level--label
			color: $mineShaft;
			font-weight: bold;
			font-family: $sansSerif;
			text-align: center;
			font-style: normal;
			line-height: 1em;
			letter-spacing: 0;
			margin: 0;
			display: flex;
			justify-content: center;
			align-content: center;
			align-items: center;
			flex-wrap: wrap;
		}

		.donors--level--radio {
			height: 20px;
			width: 20px;
			position: relative;
			display: block;
			margin: 20px 0 0 0;
			padding: 0;
			appearance: none;
			flex: 0 0 auto;
			order: 1;
			border-radius: 20px;
			box-shadow: 0 0 0px -1px rgba($amaranth, 0);
			@include subtle(box-shadow);

			@include breakpoint(large) {
				height: 30px;
				width: 30px;
				border-radius: 30px;
			}

			&:focus {
				outline: none;
				box-shadow: 0 0 10px -1px rgba($amaranth, 1);
			}

			&::before {
				content: "";
				background: $white;
				display: block;
				width: 20px;
				height: 20px;
				border-radius: 20px;
				border: 1px solid $black;
				position: absolute;
				top: 0;
				left: 0;

				@include breakpoint(large) {
					width: 30px;
					height: 30px;
					border-radius: 30px;
				}
			}

			&::after {
				content: "";
				background: $amaranth;
				display: block;
				width: 10px;
				height: 10px;
				border-radius: 10px;
				opacity: 0;
				position: absolute;
				top: 5px;
				left: 5px;
				@include subtle(opacity);

				@include breakpoint(large) {
					width: 20px;
					height: 20px;
					border-radius: 20px; // top: 5px;
					// left: 5px;
				}
			}

			&:checked {
				&~.donors--level--name {
					font-weight: 900;
				}

				&::after {
					opacity: 1;
				}
			}
		}

		&--name {
			//.donors--level--name
			font-size: 12px;
			line-height: 1em;
			margin: 0 0 10px 0;
			flex: 1 1 100%;

			@include breakpoint(large) {
				font-size: 14px;
				margin-bottom: 15px;
			}
		}

		&--range {
			//.donors--level--range
			font-size: 12px;
			line-height: 1em;
			font-weight: 900;
			color: $black;
			flex: 1 1 100%;

			@include breakpoint(large) {
				font-size: 14px;
			}
		}
	}

	&--benefits {
		//.donors--benefits
		display: flex;
		justify-content: flex-start;
		align-items: stretch;
		align-content: stretch;
		flex-wrap: wrap;
		margin-bottom: 31px;

		@include breakpoint(large) {
			width: calc(100% + 20px);
			margin-right: -10px;
			margin-left: -10px;
		}

		&--item {
			//.donors--benefits--item
			margin-right: 10px;
			margin-bottom: -1px;
			margin-left: 10px;
			background: $white;
			border: 1px solid $silver;
			display: flex;
			flex: 1 1 100%;
			order: 0;
			justify-content: space-between;
			align-items: stretch;
			align-content: stretch;

			@include breakpoint(medium) {
				// flex: 0 0 calc(50% - 20px);
				flex: 0 0 47.5%;
			}

			@include breakpoint(large) {
				// flex: 0 0 calc(33.3% - 20px);
				flex: 0 0 31.3%;
			}

			@include breakpoint(xlarge) {
				// flex: 0 0 calc(25% - 20px);
				flex: 0 0 23.1%;
			}

			&--description {
				//.donors--benefits--item--description
				padding: 15px;
				font-size: 12px;
				line-height: 1.33em;
				min-height: 0;
				flex: 1 1 auto;
				font-weight: bold;
				font-style: normal;
				letter-spacing: normal;
				color: $mineShaft;

				@include breakpoint(large) {
					font-size: 15px;
					line-height: 1.2em;
					min-height: 66px;
				}
			}

			&--check {
				//.donors--benefits--item--check
				flex: 0 0 auto;
				background: $pearl;
				width: 66px;
				position: relative;
				@include subtle(background);

				.checkmark {
					width: 11px;
					height: 22px;
					display: block;
					border: solid 4px #ffffff;
					border-top: none;
					border-left: none;
					position: absolute;
					top: 50%;
					left: 50%;
					margin: -11px 0 0 -5.5px;
					transform: rotate(45deg);
					opacity: 0;
					@include subtle(opacity);
				}
			}

			&.checked {
				order: -1;

				.donors--benefits--item--check {
					background: $amaranth;

					.checkmark {
						opacity: 1;
					}
				}
			}
		}
	}

	&--details {
		//.donors--details
		font-family: $serif;
		font-size: 14px;
		line-height: 1.64em;
		font-weight: normal;
		font-style: normal;
		letter-spacing: 0;
		color: $black;
		background: $pearl;
		padding: 20px;
		margin-left: 10px;
		margin-right: 10px;

		@include breakpoint(large) {
			margin-left: 0;
			margin-right: 0;
		}

		h3 {
			font-family: $sansSerif;
			font-size: 18px;
			font-weight: 300;
			font-style: normal;
			font-stretch: normal;
			line-height: 1em;
			letter-spacing: 0;
			color: $black;
			margin: 0 0 10px 0;

			strong {
				font-weight: bold;
			}
		}

		ul,
		li,
		p {
			font-size: inherit;
			line-height: inherit;
			color: inherit;
			font-family: inherit;
		}

		ul {
			list-style: disc outside;
			margin-left: 1.2em;
		}
	}

	&.alt {

		.prev-btn,
		.next-btn {
			background: $twine;

			&:hover,
			&:focus {
				outline: none;
				background: $black;
			}
		}

		.donors--level {
			.donors--level--radio {
				box-shadow: 0 0 0px -1px rgba($twine, 0);

				&:focus {
					box-shadow: 0 0 10px -1px rgba($twine, 1);
				}

				&::after {
					background: $twine;
				}
			}
		}

		.donors--benefits--item.checked {
			.donors--benefits--item--check {
				background: $twine;
			}
		}
	}
}
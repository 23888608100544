//pacing
$vSpaceSmall: 30px;
$vSpaceLarge: 50px;

@mixin pageSection {
	padding-top: $vSpaceSmall;
	padding-bottom: $vSpaceSmall;

	@include breakpoint(large) {
		padding-top: $vSpaceLarge;
		padding-bottom: $vSpaceLarge;
	}
}

@mixin subtle($prop, $speed: 100ms, $important: "") {
    transition: #{$speed} ease-in-out #{$prop} #{$important};
}
@mixin trans-all() {
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.blog-list {
    @include pageSection();

    &--pagination {
        //.blog-list--pagination
        margin-bottom: 40px;

        @include breakpoint(large) {
            margin-bottom: 0;
        }

        &--label {
            //.blog-list--pagination--label
            font-family: $serif;
            font-weight: normal;
            font-style: italic;
            font-size: 14px;
            line-height: 1em;
            letter-spacing: 0;
            color: $black;
            text-transform: none;
            text-align: center;
        }

        &--pages {
            //.blog-list--pagination--pages
            font-size: 0;
            line-height: 0;
            text-align: center;
            padding-top: 16px;

            .pagination {
                li {
                    &.PagedList-skipToPrevious,
                    &.PagedList-skipToNext {
                        position: relative;
                        display: inline-block;
                        vertical-align: top;
                        width: 24px;
                        height: 24px;
                    }

                    &.active {
                        a {
                            padding: 0;
                            border: 2px solid $twine;
                            color: $black;
                            cursor: default;

                            &:hover,
                            &:focus {
                                color: $black;
                            }
                        }
                    }

                    a {
                        display: inline-block;
                        vertical-align: middle;
                        width: 24px;
                        height: 24px;
                        color: $chalice;
                        text-align: center;
                        line-height: 21px;
                        font-size: 16px;
                        padding: 2px;
                        font-weight: bold;
                        margin: 0 5px;
                        text-indent: -1px;

                        @include subtle(all);

                        &:hover,
                        &:focus {
                            color: $amaranth;
                            background: transparent;
                        }

                        &.none {
                            pointer-events: none;
                            cursor: default;

                            &:hover,
                            &:focus {
                                color: $chalice;
                            }
                        }
                    }
                }

                li a[rel="prev"] {
                    display: block;
                    position: absolute;
                    top: 50%;
                    right: 50%;
                    margin: -5px -6.5px 0 0;
                    width: 10px;
                    height: 10px;
                    transform: rotate(45deg);
                    border-bottom: solid 2px $twine;
                    border-left: solid 2px $twine;
                    color: transparent;

                    @include subtle(all);

                    &:hover,
                    &:focus {
                        outline: none;
                        background: none;

                        .larr {
                            border-bottom-color: $amaranth;
                            border-left-color: $amaranth;
                        }
                    }
                }

                li a[rel="next"] {
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin: -5px 0 0 -6.5px;
                    width: 10px;
                    height: 10px;
                    transform: rotate(45deg);
                    border-top: solid 2px $twine;
                    border-right: solid 2px $twine;
                    color: transparent;

                    @include subtle(all);

                    &:hover,
                    &:focus {
                        outline: none;
                        background: none;

                        .rarr {
                            border-top-color: $amaranth;
                            border-right-color: $amaranth;
                        }
                    }
                }
            }
        }

        .page-btn {
            display: inline-block;
            vertical-align: middle;
            width: 24px;
            height: 24px;
            color: $chalice;
            text-align: center;
            line-height: 21px;
            font-size: 16px;
            padding: 2px;
            font-weight: bold;
            margin: 0 5px;
            text-indent: -1px;

            @include subtle(all);

            &:hover,
            &:focus {
                color: $amaranth;
            }

            &.current {
                padding: 0;
                border: 2px solid $twine;
                color: $black;
                cursor: default;

                &:hover,
                &:focus {
                    color: $black;
                }
            }

            &.none {
                pointer-events: none;
                cursor: default;

                &:hover,
                &:focus {
                    color: $chalice;
                }
            }
        }

        .prev-btn {
            position: relative;
            display: inline-block;
            vertical-align: middle;
            width: 24px;
            height: 24px;

            .larr {
                display: block;
                position: absolute;
                top: 50%;
                right: 50%;
                margin: -5px -6.5px 0 0;
                width: 10px;
                height: 10px;
                transform: rotate(45deg);
                border-bottom: solid 2px $twine;
                border-left: solid 2px $twine;

                @include subtle(all);
            }

            &:hover,
            &:focus {
                outline: none;
                background: none;

                .larr {
                    border-bottom-color: $amaranth;
                    border-left-color: $amaranth;
                }
            }
        }

        .next-btn {
            position: relative;
            display: inline-block;
            vertical-align: middle;
            width: 24px;
            height: 24px;

            .rarr {
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                margin: -5px 0 0 -6.5px;
                width: 10px;
                height: 10px;
                transform: rotate(45deg);
                border-top: solid 2px $twine;
                border-right: solid 2px $twine;

                @include subtle(all);
            }

            &:hover,
            &:focus {
                outline: none;
                background: none;

                .rarr {
                    border-top-color: $amaranth;
                    border-right-color: $amaranth;
                }
            }
        }
    }

    &--filters {
        //.blog-list--filters
        &--label {
            //.blog-list--filters--label
            font-family: $serif;
            font-size: 14px;
            font-weight: normal;
            font-style: italic;
            line-height: 1em;
            letter-spacing: 0;
            color: $black;
            text-transform: none;
        }

        &--filter {
            //.blog-list--filters--filter
            margin-bottom: 10px;

            @include breakpoint(large) {
                margin-bottom: 0;
            }
        }
    }

    &--item {
        //.blog-list--item
        @include pageSection();

        &--image {
            //.blog-list--item--image
            margin: 0 0 20px 0;

            @include breakpoint(medium) {
                margin-bottom: 0;
            }

            img {
                display: block;
                width: 250px;
                height: auto;
                max-width: none;
                margin: 0;

                @include breakpoint(medium) {
                    width: 100%;
                }
            }
        }

        &--content {
            //.blog-list--item--content
            font-family: $sansSerif;
            color: $black;
            padding: 0;

            @include breakpoint(large) {
                padding-left: 20px;
            }
        }

        &--title {
            //.blog-list--item--title
            font-weight: bold;
            font-size: 24px;
            line-height: 1.2em;

            @include breakpoint(large) {
                font-size: 30px;
            }
        }

        &--date {
            //.blog-list--item--date
            margin: 20px 0 0 0;
            font-family: $serif;
            font-weight: normal;
            font-style: italic;
            font-size: 14px;
            line-height: 1.86em;
            color: $black;
            padding-bottom: 1rem;

            @include breakpoint(large) {
            }
        }

        &--summary {
            //.blog-list--item--summary
            font-family: $serif;
            font-size: 13px;
            line-height: 1.63em;
            text-align: justify;
            margin: 20px 0 0 0;

            @include breakpoint(large) {
                font-size: 16px;
                margin-top: 40px;
            }
        }

        &--actions {
            //.blog-list--item--actions
            margin: 20px 0 0 0;

            @include breakpoint(large) {
                margin-top: 40px;
            }
        }
    }
}

.section-title {
	&--supertitle {
		//.section-title--supertitle
		font-family: $sansSerif;
		font-weight: bold;
		font-style: normal;
		line-height: 1;
		color: $scorpion;
		margin: 0 0 10px 0;
		font-size: 16px;

		@include breakpoint(large) {
			font-size: 22px;
		}
	}

	&--title {
		//.section-title--title
		font-family: $sansSerif;
		font-weight: 300;
		font-style: normal;
		color: $black;
		font-size: 48px;
		line-height: 1;
		letter-spacing: -0.3px;
		margin: 0 0 30px 0;

		@include breakpoint(large) {
			font-size: 54px;
			letter-spacing: -0.4px;
		}

		&::before {
			content: "";
			width: 45px;
			height: 0;
			display: block;
			border-top: 2px solid $amaranth;
			margin: 0 0 15px 0;
		}
	}

	&.small {
		.section-title--supertitle {
			font-size: 14px;

			@include breakpoint(large) {
				font-size: 16px;
			}
		}

		.section-title--title {
			font-size: 24px;

			@include breakpoint(large) {
				font-size: 30px;
			}
		}
	}
}
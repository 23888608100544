﻿.grid-section .at-row > .clearfix {
    padding: 0;
    margin: 0;
}
.grid-section .at-row > .clearfix > .columns {
    padding: 0;
}
.grid-section .at-row > .row-flex {
    display: flex;
}
.grid-section > div > .columns {
    padding: 0;
    margin: 0;
}

@media print, screen and (max-width: 63em) {
    .grid-section .at-show-for-small-full {
        max-width: 100% !important;
        flex: 0 0 100% !important;
    }
    .grid-section .at-show-for-medium-up {
        display: none;
    }
    .grid-section .at-show-for-small-full .row.hide-for-large .upcoming-performances {
        float: none !important;
    }
}
.hidden {
    display: none !important;
}

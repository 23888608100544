.bio-content {
	.column {
		@include pageSection();
	}

	&--productions {
		font-family: $serif;
		font-size: 14px;
		font-weight: bold;
		font-style: normal;
		line-height: 1.86em;
		letter-spacing: 0;
		color: $black;

		a {
			display: inline-block;
			text-decoration: underline;
			font-family: $sansSerif;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			line-height: 1em;
			letter-spacing: 0;
			color: $black;
			@include subtle(color);

			&:focus,
			&:hover {
				color: $amaranth;
			}
		}
	}

	&--image {
		//.bio-content--image
		width: 100%;
		height: auto;
		max-width: none;
        margin-top: 40px;
	}
}